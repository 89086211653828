<template>
  <div class="select">
    <div class="left">
      <span class="require" v-if="props.require">*</span>
      <span class="title">{{ props.lable }}</span>
    </div>
    <slot name="content">
      <div class="right" @click="hanldeSelect">
        <span :class="!props?.value?.name ? 'placeholder' : 'choose'">{{
          props?.value?.name || '请选择'
        }}</span>
        <img :class="show ? 'active' : 'uncHeck'" src="@/assets/image/fixright.png" />
      </div>
    </slot>
  </div>
  <van-action-sheet
    v-model:show="show"
    :title="title"
    :close-on-popstate="true"
    :actions="props.actions"
    @select="onSelect"
  />
</template>
<script setup>
import { ref } from 'vue';

const emit = defineEmits(['update:value', 'change']);
const props = defineProps({
  value: {
    type: Object,
    default: () => ({})
  },
  //是否必选
  require: {
    type: Boolean,
    default: true
  },
  actions: {
    type: Array,
    default: () => []
  },
  lable: {
    type: String,
    default: 'lable'
  },
  title: {
    type: String,
    default: '请选择'
  }
});

const hanldeSelect = () => {
  show.value = true;
};

const show = ref(false);

const onSelect = item => {
  show.value = false;
  emit('update:value', item);
  emit('change', item);
};
</script>
<style lang="scss" scoped>
.select {
  height: 115px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 24px;
  font-size: 28px;
  border-bottom: 1px solid #efefef;
  .left {
    .require {
      color: #fd6336;
      margin-right: 10px;
      vertical-align: middle;
    }
    .title {
      font-weight: bold;
    }
  }
  .right {
    .placeholder {
      margin-right: 16px;
      color: rgba(0, 0, 0, 0.3);
    }
    .choose {
      margin-right: 16px;
      color: #000000;
    }
    img {
      width: 28px;
      transition: all 0.2s ease-out;
    }
  }
}
.active {
  transform: rotate(0deg) !important;
}
.uncHeck {
  transform: rotate(90deg) !important;
}
</style>
