<template lang="html">
  <div class="repairapply">
    <div class="content-box">
      <div class="base-box">
        <YcSelect lable="摊位选择" :actions="stallActions" v-model:value="stall"></YcSelect>
        <YcSelect
          lable="设备报修"
          :actions="typeActions"
          v-model:value="reportType"
          @change="handleChange"
        ></YcSelect>
      </div>
      <div class="report">
        <YcSelect lable="报修内容"
          ><template #content><span></span></template
        ></YcSelect>
      </div>
      <div class="textarea-box">
        <van-field
          class="textarea"
          v-model="formData.repair_details"
          rows="5"
          autosize
          type="textarea"
          placeholder="请简短描述内容"
        />
      </div>
      <div class="pic-wrapper">
        <div class="title">
          <div class="lable">图片上传</div>
          <div class="count">{{ picList?.length || 0 }}<span>/9</span></div>
        </div>
        <van-field name="uploader">
          <template #input>
            <van-uploader v-model="picList" :after-read="afterRead" :max-count="9" />
          </template>
        </van-field>
      </div>
    </div>
    <div class="bottom">
      <div class="record">
        <img src="../../assets/image/detail.png" alt="" />
        <span @click="goUrl"> 查看记录 </span>
      </div>
      <div class="submit" @click="submit">
        {{ !queryId ? '提交' : '修改' }}
      </div>
    </div>
    <van-popup v-model:show="showPopup" :close-on-click-overlay="false" round>
      <div class="dialog">
        <img class="tips-icon" src="@/assets/image/tips.png" alt="提交成功" />
        <img class="close" @click="handleCloseClick" src="@/assets/image/contractClose.png" />
        <div class="content">提交信息成功</div>
        <div class="button" @click="handleCloseClick">知道了</div>
      </div>
    </van-popup>
  </div>
</template>
<script setup>
import { ref, inject, onMounted, watch, nextTick } from 'vue';
import { Toast } from 'vant';
import YcSelect from './cpns/ycSelect.vue';
const $require = inject('$require');
const { router, route } = inject('ctx');
const typeActions = ref([{ name: '暂无数据', value: -99, disabled: true }]);
const stallActions = ref([]);
const showPopup = ref(false);
const formData = ref({});
const picList = ref([]);
const stall = ref({});
const reportType = ref({});
const localdata = window.localStorage.getItem('propertyUserInfo')
  ? JSON.parse(window.localStorage.getItem('propertyUserInfo'))
  : {};
const queryId = route?.query?.id ?? false;

watch(
  () => stall.value,
  (newValue) => {
    reportType.value = {};
    formData.value.address = newValue.name;
    getDeviceTypeByStallId(newValue.value);
  }
);
onMounted(async () => {
  await getStallList();
  queryId && getDetailInfo();
});
//返现选项
const getDetailInfo = async () => {
  const res = await getDetail(queryId);
  const detailItem = res.data;
  const chooseStall = stallActions.value.find((item) => item.name == detailItem.address);
  stall.value = { name: chooseStall.name, value: chooseStall.value };
  nextTick(() => {
    reportType.value = { name: detailItem.repair_type, value: -99 };
  });
  formData.value.repair_details = detailItem.repair_details;
  formData.value.repair_type = detailItem.repair_type;
  formData.value.repair_id = detailItem.repair_id;
  picList.value = JSON.parse(detailItem.repair_images).map((item) => ({
    url: item,
    status: 'done',
    uploadUrl: item
  }));
};

//获取商户摊位列表
const getStallList = () => {
  return new Promise((resolve) => {
    $require.HttpPost('/h5/merchants/getMerchantsMessage').then((res) => {
      stallActions.value = res?.data?.contract_stall_number?.map((item) => ({
        name: item.merchants_stall_number,
        value: item.merchants_stall_id
      })) ?? [{ name: '暂无数据', value: -99, disabled: true }];
      resolve();
    });
  });
};

//获取摊位绑定设备类型
const getDeviceTypeByStallId = (id) => {
  $require
    .HttpGet('/h5/facility/list', { merchants_stall_id: id, pageNum: 1, pageSize: 999 })
    .then((res) => {
      if (!res?.data?.list?.length) {
        typeActions.value = [{ name: '暂无数据', value: -99, disabled: true }];
        return;
      }
      typeActions.value = res?.data?.list?.map((item) => ({
        name: item.name,
        value: item.facility_id
      }));
    });
};
//根据id获取详情
const getDetail = (id) => {
  const params = {
    id
  };
  return $require.HttpGet('/h5/repair/edit', params);
};
//上传文件
const afterRead = (file) => {
  file.status = 'uploading';
  let formData = new FormData();
  formData.append('file', file.file);
  $require.HttpPost('/system/upload/upImg', formData).then((res) => {
    if (!res?.data?.fileInfo?.hostUrl) {
      file.status = 'failed';
      return;
    }
    let src = process.env.VUE_APP_IMG_URL + res.data.fileInfo.hostUrl;
    file.uploadUrl = src;
    file.status = 'done';
  });
};
const handleChange = ({ name, value }) => {
  formData.value.repair_type = name;
  formData.value.facility_id = value;
};
function submit() {
  if (!formData.value.repair_type) {
    Toast('请选择设备报修');
    return;
  }
  if (!formData.value.repair_details) {
    Toast('请输入报修内容');
    return;
  }

  const params = {
    ...formData.value,
    repair_images: picList.value
      .filter((item) => item.status == 'done')
      .map((item) => item.uploadUrl),
    merchants_name: localdata.merchantsName,
    merchants_mobile: localdata.merchantsContact
  };

  !queryId
    ? $require.HttpPost('/h5/repair/add', params).then(() => {
        showPopup.value = true;
      })
    : $require.HttpPost('/h5/repair/edit', params).then(() => {
        showPopup.value = true;
      });
}

function goUrl() {
  router.push('/repair/record');
}
const handleCloseClick = () => {
  showPopup.value = false;
  router.push('/home');
};
</script>
<style lang="scss" scoped>
.repairapply {
  box-sizing: border-box;
  height: 100vh;
  padding: 18px 32px 130px 32px;
  background-color: #f9f9f9;
  .content-box {
    background-color: #fff;
    .report::v-deep {
      .select {
        border-bottom: none;
      }
    }
    .textarea-box {
      padding: 0 24px;
      .textarea {
        padding: 0 10px;
        background: #f8f8f8;
        border-radius: 10px 10px 10px 10px;
      }
    }
    .pic-wrapper {
      padding: 0 24px;

      .title {
        font-size: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          color: rgba(0, 0, 0, 0.3);
        }
      }
      .lable {
        font-weight: bold;
        font-size: 28px;
        margin: 24px 0;
      }
    }
  }

  .bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    padding: 0 16px;
    align-items: center;
    justify-content: space-between;
    height: 130px;
    background-color: #fff;
    .record {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 150px;

      img {
        width: 40px;
      }
      span {
        font-size: 28px;
        font-weight: 400;
        color: #529100;
      }
    }
    .submit {
      width: 516px;
      height: 96px;
      background: #85c226;
      border-radius: 110px 110px 110px 110px;
      font-size: 32px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      line-height: 96px;
      margin: 0 auto;
    }
  }

  .submit {
    width: 654px;
    height: 96px;
    background: #cb191a;
    border-radius: 110px 110px 110px 110px;
    font-size: 32px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 96px;
    margin: 0 auto;
  }

  .sec {
    font-size: 28px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    padding: 0 32px;
    box-sizing: border-box;

    .content {
      font-size: 28px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.6);
      width: 686px;
      height: 220px;
      background: #ffffff;
      border-radius: 10px;
      border: 2px solid rgba(0, 0, 0, 0.1);
      padding: 20px 0 0 20px;
      box-sizing: border-box;
      margin-top: 24px;
    }

    .content::placeholder {
      font-size: 28px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.3);
    }
  }

  .check {
    font-size: 28px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #cb191a;
    text-align: centerF;
    margin: 56px 0 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 28px;
      height: 28px;
    }
  }
}

.dialog {
  width: 666px;
  height: 494px;
  background: #ffffff;
  border-radius: 20px 20px 20px 20px;

  opacity: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  .close {
    position: absolute;
    width: 36px;
    right: 32px;
    top: 32px;
  }
  .tips-icon {
    width: 204px;
  }
  .content {
    font-size: 36px;
    font-weight: bold;
  }
  .button {
    width: 274px;
    height: 96px;
    background: #ffffff;
    border-radius: 200px 200px 200px 200px;
    opacity: 1;
    border: 2px solid rgba(0, 0, 0, 0.1);
    font-size: 32px;
    text-align: center;
    line-height: 96px;
  }
}
</style>
